import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EmailValidate } from '../../common.model';
import { CommonService } from "../../common.service";
import { GlobalEmailValidateService } from '../../globalemailvalidate.service';
@Component({
  selector: 'app-email-validator',
  templateUrl: './email-validator.component.html',
  styleUrls: ['./email-validator.component.css']
})
export class EmailValidatorComponent implements OnInit {
  emailValue = "hye there iss shubham";
  @Input() email:any
  @Output() emailvalidate=new EventEmitter<any>();
  constructor( private _returnServic : CommonService
    , private globalEmailValidateService:GlobalEmailValidateService ) { }
  emailGet(){
    alert("email is working");
  }
_data:any;
_EmailValidate:EmailValidate
  ngOnInit(): void {
     this.addNewItem('')
  }
  ResponseCode:string=''
  ResponseMessage:string=''
  addNewItem(e) {
    var _EmailValidate= new EmailValidate()
   return this.globalEmailValidateService.emailvalidation(e)
  }
}
