import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { modal } from './component/modal.component';
import { ModalHeaderComponent } from './component/src/modal-header';
import { ModalBodyComponent } from './component/src/modal-body';
import { ModalFooterComponent } from './component/src/modal-footer';
export * from './component/modal.component';
export * from './component/message.component';
export * from './component/src/modal-header';
export * from './component/src/modal-body';
export * from './component/src/modal-footer';
export * from './component/src/modal-instance';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        modal,
        ModalHeaderComponent,
        ModalBodyComponent,
        ModalFooterComponent
    ],
    exports: [
        modal,
        ModalHeaderComponent,
        ModalBodyComponent,
        ModalFooterComponent
    ]
})
export class popup {
}
