import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { Observable } from 'rxjs';
import { FormsModule, FormBuilder, Validators } from '@angular/forms';
import { FormControl, FormGroup, NgModel, NgForm } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { Address } from './address.model';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { TypeLookup } from './TypeLookup.model';
import { StatesService } from './states.service';
import { CountriesService } from './countries.service';
import { AddressService } from './address.service';
import { Util } from '../app.util';
@Component({
  selector: 'Address-Template',
  templateUrl: './address.html',
})
export class AddressTemplateEditor {
  @Input() address: Address;
  @Input() addressEditor: any;
  @Output() notifyAddress: EventEmitter<Address> = new EventEmitter<Address>();
  @ViewChild('f') _f: NgForm;
  CurrentAddress: Address = new Address();
  parentForm: string;
  selectedCountryID: number;
  @Input() AddressTypeID: number = 0;
  selectedStateID: number;
  setCountryID: number;
  addressForm: FormControl;
  addressTypeName: string;
  IsLoaded: boolean;
  TypeLookupList: TypeLookup[];
  isSaveClick: boolean = false;
  setRequiredCountry: boolean = false;
  setRequiredState: boolean = false;
  Countries: any;
  States: any;
  constructor(
    private countriesService: CountriesService,
    private _util: Util,
    private _addressService: AddressService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _stateService: StatesService,
    private _countryService: CountriesService
  ) {
    this.countriesService.loadCountries().subscribe((types) => {
      this.Countries = types;
    });
  }
  ngOnInit() {
    this.IsLoaded = true;
    this.parentForm = this.address.ParentFormName;
    this.CurrentAddress = this.address;
    if (this.address.PostType == 'insert') {
      this.CurrentAddress = new Address();
      this.CurrentAddress.RowNumber = this.address.RowNumber;
      this.CurrentAddress.PostType = this.address.PostType;
      if (this.AddressTypeID != 0) {
        this.CurrentAddress.AddressTypeID = this.AddressTypeID;
      }
    } else {
      this.CountrySelected();
    }
  }

  CountrySelected() {
    this._stateService
      .loadStates(this.CurrentAddress.CountryID)
      .subscribe((_result) => {
        this.States = _result;
      });
  }
  StateSelected() { 
    console.log('StateSelected')
  }

  onSave(form) {
    if (!form) {
      form = this._f;
    }

    if (!form.valid) {
      for (let i in form.controls) {
        form.controls[i].markAsTouched();
      }
      form.valueChanges.subscribe((data) => {
        this.isSaveClick = !form.valid;
      });

      this.isSaveClick = true;
      this._util.warning(
        'Please fill all the required information with correct format',
        'warning'
      );
      return;
    }
    this.notifyAddress.emit(this.CurrentAddress);
  }

  onCancel() {
    this.CurrentAddress.PostType = 'cancle';
    this.notifyAddress.emit(this.CurrentAddress);
  }
}
