/* global.service.ts */

import { Injectable, Inject } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { ConfigurationConstants } from '../shared/constants';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { EmailValidate } from "../../app/shared/common.model";
@Injectable()
export class GlobalEmailValidateService {

  baseUrl: string;
  _EmailValidate:EmailValidate
  private Data = {};
  constructor(@Inject(DOCUMENT) private document, private http: HttpClient) {
    this.baseUrl = ConfigurationConstants.BASEURL;

  }
  // emailvalidation(email: any): Observable<any> {
  //     return this.http.get(`${this.baseUrl}emailvalidate/emailvalidation/${email}`);
    
    
  // }
  emailValidated(email: any){
   var _EmailValidate= new EmailValidate()
    this.emailvalidation(email).subscribe(res=>{
      _EmailValidate.ResponseCode=res.validated_add.Items[0].ResponseCode
     _EmailValidate.ResponseMessage=res.validated_add.Items[0].ResponseMessage
    })
    return _EmailValidate
  }
  emailvalidation(email: any): Observable<any> {
    let data=''
    return this.http.get<any>(`${this.baseUrl}emailvalidate/emailvalidation/${email}`)
      .pipe(
        tap(
        ),
        catchError(this.handleError<any>('getHeroes', []))
      );
      
  }
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
  
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
  
      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);
  
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
