import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./auth.guard";
// imports: [RouterModule.forChild(Routes)]
const routes: Routes = [

  // //------------------------stage start
  // { path: 'portal',
  // //  .forChild('./pages/portal/portal.module#PortalModule')./pages/portal/portal.module
  // loadChildren: () => import ('./pages/portal/portal.module').then (m => m.PortalModule) },
  // {
  //   path: 'login',
  //   loadChildren: () => import ('./authentication/login/login.module').then (m => m.LoginModule),
  // },
  // {
  //   path: 'auth',
  //   loadChildren: () => import ('./authentication/authentication.module').then (m => m.AuthenticationModule),
  // },
  // {
  //   path: '',
  //   loadChildren: () => import ('./back-office/back-office.module').then (m => m.BackOfficeModule),
  //   canActivate: [AuthGuard],
  // },
  // //------------------------stage start

  //------------------------production start
  {
    path: "portal",
    loadChildren: () =>
      import("./pages/portal/portal.module").then((m) => m.PortalModule),
  },
  {
    path: "bo/login",
    // loadChildren: "./authentication/login/login.module#LoginModule",
    loadChildren: () =>
      import("./authentication/login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "bo/auth",
    loadChildren: () =>
      import("./authentication/authentication.module").then(
        (m) => m.AuthenticationModule
      ),
  },
  {
    path: "bo",
    // loadChildren: "./back-office/back-office.module#BackOfficeModule",
    loadChildren: () =>
      import("./back-office/back-office.module").then(
        (m) => m.BackOfficeModule
      ),

    canActivate: [AuthGuard],
  },
  {
    path: "",
    redirectTo: "/portal/res/return/t3/dsc2/prod/login/en-us",
    pathMatch: "full",
  },
  //------------------------production stop

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
