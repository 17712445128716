import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthenticationModule } from './authentication/authentication.module';
import { SharedModule } from './shared/shared.module';
import { BackOfficeModule } from './back-office/back-office.module';
import { HttpClientModule } from '@angular/common/http';
import { ControlsModule } from './controls/controls.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { Util } from './app.util';
import { WINDOW_PROVIDERS } from './app.window';
import { CKEditorModule } from 'ngx-ckeditor';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { HighchartsChartModule } from '../app/highcharts-angular/src/lib/highcharts-chart.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  exports: [
    
  ],
  imports: [
    HighchartsChartModule,
    NgMultiSelectDropDownModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    AuthenticationModule,
    SharedModule,
    BackOfficeModule,
    HttpClientModule, 
    FormsModule,
    ControlsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      'closeButton': true,
      'newestOnTop': true,
      'progressBar': true,
      'positionClass': 'toast-top-right',
      'preventDuplicates': false,
      'timeOut': 10000,
      'extendedTimeOut': 1000
    }),
    CKEditorModule
  ],
  providers: [
    Util,
    WINDOW_PROVIDERS
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
