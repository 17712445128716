import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthenticationComponent } from "./authentication.component";

const routes: Routes = [
  {
    path: "auth",
    component: AuthenticationComponent,
    children: [
      { path: "login", 
      loadChildren: () => import('./login/login.module').then (m => m.LoginModule) },
      {
        path: "password",
        // loadChildren: "./password/password.module#PasswordModule",
        loadChildren: () => import('./password/password.module').then (m => m.PasswordModule),
      },
      {
        path: "resetpassword",
        // loadChildren:
        //   "./resetpassword/resetpassword.module#ResetpasswordModule",
        loadChildren: () => import('./resetpassword/resetpassword.module').then (m => m.ResetpasswordModule),

      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthenticationRoutingModule {}
