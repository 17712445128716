import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject, EMPTY } from "rxjs";
import { switchMap } from "rxjs/operators";
import { ConfigurationConstants } from "src/app/shared/constants";
import { AuthService } from "src/app/authentication/auth.service";
import {
  HttpHeaders,
  HttpClient,
} from "@angular/common/http";
import { Util } from "src/app/app.util";

@Injectable()
export class ReturnsService {
  tenantAdmins: Observable<any>;
  _tenantAdmins: BehaviorSubject<any>;
  baseUrl: string;
  carrierAPI: string;
  actionUrl: string;

  constructor(
    private http: HttpClient,
    private _auth: AuthService,
    private _util: Util
  ) {
    this.baseUrl = ConfigurationConstants.BASEURL;
  }

  order(filterValue: string): Observable<any> {
    var options = filterValue.split("|");
    var data = {
      ordernumber: options[0],
      email: options[1],
      LanguageCode: options[2],
      BrandCode: (options.length > 3 && options[3]) || "none",
    };
    return this.http.post(
      `${this.baseUrl}return/findorder`,
      JSON.stringify(data)
    );
  }

  getTypes(typegroup: string): Observable<any> {
    return this.http.get(this.baseUrl + "common/typelookup/" + typegroup);
  }
  orders(AdvancefilterValue: any): Observable<any> {
    return this.http.post(
      `${this.baseUrl}return/orders`,
      JSON.stringify(AdvancefilterValue)
    );
  }

  returnOrder(filterValue: string, queueStatus: string,requestFrom:string
    ): Observable<any> {
    if (requestFrom.includes("globalqueueold")) {
      return this.http.get(`${this.baseUrl}return/${queueStatus}/${filterValue}/old`);

    }
    else{
    return this.http.get(`${this.baseUrl}return/${queueStatus}/${filterValue}`);
    }
  }


  cancelReturnOrder(refnumber: string): Observable<any> {
    return this.http.get(`${this.baseUrl}return/cancelrmaqueue/cacelrma/${refnumber}`);
  }

  returnOrderclient(filterValue: string): Observable<any> {
    return this.http.get(
      `${this.baseUrl}return/client/get/${filterValue}`
    );
  }
  cancelrequest(data: any): Observable<any> {
    return this.http.post(
      `${this.baseUrl}return/cancelReturnRequest`,
      JSON.stringify(data)
    );
  }
  return(): boolean {
    return true;
  }

  getReturnOrders(
    startIndex,
    endIndex,
    sortColumn,
    sortDirection,
    filterValue,
    Status,
    fromDate,
    toDate,
    brandCode,
    userStatus
  ): Observable<any> {
    return this.http.get(
      `${this.baseUrl}return/${startIndex}/${endIndex - startIndex
      }/${sortColumn}/${sortDirection}/${filterValue}/${fromDate}/${toDate}/${Status}/${brandCode}/${userStatus}`
    );
  }

  getUnseenReturnOrders(
    startIndex,
    endIndex,
    sortColumn,
    sortDirection,
    filterValue,
    Status,
    fromDate,
    toDate,
    brandCode
  ): Observable<any> {
    return this.http.get(
      `${this.baseUrl}return/unseenrma/${startIndex}/${endIndex - startIndex
      }/${sortColumn}/${sortDirection}/${filterValue}/${fromDate}/${toDate}/${Status}/${brandCode}`
    );
  }
  getlabels(data: any): Observable<any> {
    return this.http.post(
      `${this.carrierAPI}api/carrier`,
      JSON.stringify(data)
    );
  }

  saveGNRequest(data: any): Observable<any> {
    return this.http.post(
      `${this.baseUrl}return/saveGNreturn`,
      JSON.stringify(data)
    );
  }

  saveRMA(data: any, isWarrantyReturnApproved: boolean): Observable<any> {
    if (
      data.returnfreight &&
      data.returnfreight.active == true &&
      isWarrantyReturnApproved == true
    ) {
      return this.getlabels(data).pipe(
        switchMap((result) => {
          if (result.status != "ERROR" && result.status != "FATAL") {
            var _parcels = data.shipment.parcels;
            if (result.packages) {
              data.shipment.parcels = result.packages;
            }
            data.labels = result.labelURLs;
            data.custom_declaration = result.customURLs;

            //=========New for oversized========//
            if (data.shipment.parcels.length == _parcels.length) {
              for (
                let index = 0;
                index < data.shipment.parcels.length;
                index++
              ) {
                data.shipment.parcels[index].oversized =
                  _parcels[index].oversized;
              }
            }
            return this.http.post(
              `${this.baseUrl}return/customer`,
              JSON.stringify(data)
            );
          } else {
            this._util.error(
              "An error has occured while generating the shipping label. Apologies for the inconvenience." +
              (result.errorMessage ? " - " + result.errorMessage : ""),
              "Alert"
            );
            return EMPTY; 
          }
        })
      );
    } else {
      return this.http.post(
        `${this.baseUrl}return/customer`,
        JSON.stringify(data)
      );
    }
  }

  uploadImages(data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}uploads/files`, data);
  }

  postReturnNotes(data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}return/note`, JSON.stringify(data));
  }

  postAuthorizedRequest(data: any): Observable<any> {
    let slide = localStorage.getItem('slideactive')
    if (slide == 'true') {
    return this.http.post(
      `${this.baseUrl}return/boOrderRejectD365`,
      JSON.stringify(data)
    );
    }
    else{
      return this.http.post(
        `${this.baseUrl}return/boOrderReject`,
        JSON.stringify(data)
      );
    }
  }
  // postAuthorizedRequest(data: any): Observable<any> {
   
  //     return this.http.post(
  //       `${this.baseUrl}return/boOrderReject`,
  //       JSON.stringify(data)
  //     );
    
  // }

  getReturnNotes(refnumber: string): Observable<any> {
    return this.http.get(`${this.baseUrl}return/notes/${refnumber}`);
  }

  scanRMA(
    RMANumber: string,
    oldStatus: string,
    newStatus: string
  ): Observable<any> {
    return this.http.get(
      `${this.baseUrl}return/status/${RMANumber}/${oldStatus}/${newStatus}`
    );
  }

  UpdateRMAFile(data: any, rmanumber: string): Observable<any> {
    return this.http.post(`${this.baseUrl}return/files/${rmanumber}`, data);
  }

  getUBParcelStatus1(trackNumber): Promise<any> {
    let headers: HttpHeaders = new HttpHeaders({
      api_key: "72f01b7f-9bc6-41c9-ba1e-8d7481d2cfa7",
    });
    let httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    };
    httpOptions.headers = httpOptions.headers.append(
      "api_key",
      "72f01b7f-9bc6-41c9-ba1e-8d7481d2cfa7"
    );
    return new Promise((resolve, reject) => {
      // this.http .get( "https://api.coureon.com/api/v1_6/tracking/" + trackNumber, httpOptions)
      //   .toPromise()
      //   .then((res) => {
      //     resolve(res);
      //   });
    });
  }

  GenerateCustomDecalartionLabel(data: any): Observable<any> {
    return this.http.post(
      `${this.carrierAPI}api/carrier/GenerateCustomDecalartionLabel`,
      JSON.stringify(data)
    );
  }

  getPartners(): Observable<any> {
    return this.http.get(`${this.baseUrl}common/partners/assigned`);
  }

  onlineProductCatalog(sku: string, lang: string): Observable<any> {

    return this.http.get(
      `${this.baseUrl}return/jsn_onlieProductDetail/${sku}/${lang}`
    );
  }
  savecheck(data: any): Observable<any> {
    return this.http.post(
      `${this.baseUrl}return/saveTwowayChatEnable/`,
      data
    );
  }

  postNotes(returnheaderid: any): Observable<any> {
    return this.http.get(
      `${this.baseUrl}return/updatenotestab/upd/${returnheaderid}`
    );
  }
  onunblockserial(Serialnumber: string): Observable<any> {

    return this.http.get(
      `${this.baseUrl}return/unblockserial/unblock/${Serialnumber}`
    );
  }
  onunblockserialcancel(selectedItemIds: any): Observable<any> {

    return this.http.get(
      `${this.baseUrl}return/unblockserialcancel/unblock/${selectedItemIds}`
    );
  }
  GetUserDetails(InternalCode: string): Observable<any> {
    return this.http.get(
      `${this.baseUrl}users/Useretails/rma/views/${InternalCode}`
    );
  }
  
  cancelserial(Serialnumber: string): Observable<any> {
    return this.http.get(
      `${this.baseUrl}return/cancelserial/cancel/${Serialnumber}`
    );
  }
  onserialcancel(selectedItemIds: any): Observable<any> {

    return this.http.post(
      `${this.baseUrl}return/serialcancel/serial/`,JSON.stringify(selectedItemIds)
    );
  }

  getState(countryCode: string): Observable<any> {
    return this.http.get(`${this.baseUrl}return/allstateByCountryCode/${countryCode}`);
  }
  updateRMAaddress(data: any): Observable<any> {
    // return this.http.post(
    //   `${this.baseUrl}return/update/updaetgaiabpaddress/`,JSON.stringify(data)
    // );
    return this.http.post(
      `${this.baseUrl}return/update/updaetgaiabpaddress`,
      JSON.stringify(data)
    );
  }
  
  getStatus(): Observable<any> {
    return this.http.get(
      `${this.baseUrl}return/status/values/allstatus`
    );
  }
  onholdcommentpost(onholdsave: any): Observable<any> {
    return this.http.post(
      `${this.baseUrl}consumer_return/onhold/save/savehonhold`,
      JSON.stringify(onholdsave)
    );
  }
  jsn_warrantyChecker(sn: string, country: string) {
    return this.http.get(
      `${this.baseUrl}return/jsn_warrantyChecker/${sn}/${country}`
    );
  }
  warrantyCheckerNew(sn: string, country: string,popdate:string,selectionType:string) {
    let Noserialfound='no'
    return this.http.get(`${this.baseUrl}portal/warrantychecker/new/warrantychecker/api/${sn}/${country}/${popdate}/${selectionType}/${Noserialfound}`);
  }
  loadcountry(flow,countrycode): Observable<any> {
    return this.http.get(
      `${this.baseUrl}return/GetCountry/end/${flow}/${countrycode}`
    );
  }
}
