import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { Subscription } from "rxjs";
import { GlobalVariableService } from "src/app/shared/globalvariable.service";
import { CommonService } from "src/app/shared/common.service";
import { User } from "../../shared/common.model";
import { ApplicationInsightsService } from "src/app/ApplicationInsightsService";
import { BsModalComponent } from "ng2-bs3-modal";
import { AuthService } from "../../authentication/auth.service";
import { Util } from "src/app/app.util";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";

declare var $: any;
@Component({
  selector: "app-navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.css"],
  providers: [AuthService, ApplicationInsightsService],
})
export class NavigationComponent implements OnInit {
  errorMessage: string;
  message: string;
  slideactive: boolean = true;
  title: string;
  LogInUserName: string;
  value_parameter: string;
  LoggedInUserEmail: string;
  LogInUserPartnerName: string;
  LoginUserPartnerType: string;
  UserAssignLocation: string;
  UserAssignedRole: string;
  LogInUserImageSrc: string = "assets/img/noimage.png";
  TrialMessage: string;
  IsShowTrialMessage: boolean = false;
  IsDashBordShow: boolean = false;
  @ViewChild("changePassword") pwdmodal: any;
  @ViewChild("modal1") modal: BsModalComponent;
  lastPing?: Date = null;
  toLogoutMessage: boolean = false;
  PartnerInfo: any;
  UserInfo = { UserImage: "", UserId: 0 };
  roleCount: number;
  AssignedRoles = [];
  havePartner: boolean = false;
  chatbar: boolean = false;
  PortalImageSrc: string;
  canChangeTheme: boolean = false;
  _subscription: Subscription;
  _route: string;
  encryptionKey: string = environment.encryptionKey;
  alertLabel: string = "";
  isSubmittedForPwdChange: boolean = false;
  pwdchangeData = {
    actualpassword: "",
    newPassword: "",
    confirmPassword: "",
    token: "",
  };
  constructor(
    private _global: GlobalVariableService,
    private commonser: CommonService,
    private authService: AuthService,
    private _util: Util,
    private _appInsightService: ApplicationInsightsService,
    private router: Router,


  ) {

  }

  ngOnInit() {
    $.InitiateSideMenu();
    this.value_parameter = environment.envsource
    this.commonser.getDefaultPartner().subscribe(
      (_res) => {
        if (_res.length != 0) {
          this._global.setItem("partnerinfo", _res);
          this.havePartner = true;
          this.PartnerInfo = this._global.getItem("partnerinfo");
          var data = this.PartnerInfo[Object.keys(this.PartnerInfo)[0]];
          this.UserInfo.UserImage = data[0][0].UserImage;
          this.UserInfo.UserId = data[0][0].UserId;
          this._appInsightService.setUserId(data[0][0].UserId);
          this.LogInUserImageSrc =
            this.UserInfo.UserImage == null || this.UserInfo.UserImage == ""
              ? "assets/img/noimage.png"
              : this.UserInfo.UserImage;
          this.PortalImageSrc = data[0][0].PortalIcon;
          if (data[0][0].DashBoardMasterID == 6) this.IsDashBordShow = false;
          else this.IsDashBordShow = true;

          var role = this.PartnerInfo.recordsets[0][0]["RoleName"];
          this.AssignedRoles.push(role);
          this.roleCount = 1;
          this.LogInUserPartnerName = JSON.stringify(
            data[0][0].LogInUserPartnerName
          ).replace(/\"/g, "");
          this.LoginUserPartnerType = data[0][0].TypeCode;
          this.LogInUserName = data[0][0].LogInUserName;
          this.LoggedInUserEmail = JSON.stringify(data[0][0].LogInUserEmail).replace(
            /\"/g,
            ""
          );
          var UserAssignedRole = JSON.stringify(data[0][0].RoleName).replace(
            /\"/g,
            ""
          );
          if (UserAssignedRole.indexOf("Admin") > -1)
            this.canChangeTheme = true;
          if (UserAssignedRole.indexOf(",") > -1) {
            var newuser = UserAssignedRole.split(",");
            this.UserAssignedRole = newuser[0] + ",...";
          } else {
            this.UserAssignedRole = UserAssignedRole;
          }
        } else {
          localStorage.clear();
          sessionStorage.clear();

          this._util.error(
            "User profile Role is Inactive. Please contact System Administrator for login.",
            "Error"
          );
          this.router.navigate(["/auth/login"]);
        }
      },
      (error) => (this.errorMessage = <any>error)
    );
    localStorage.setItem('slideactive', 'true')
    if (this.value_parameter == 'uat') {
      this.authService.getstatus().subscribe(
        (data) => {
          // console.log("userlogdata", data.result[0][0].Active)
          this.slideactive = data.result[0][0].Active;
          localStorage.setItem('slideactive', this.slideactive.toString())
          if (this.slideactive == true) {
            localStorage.setItem('slideactive', 'true')
          } else {
            localStorage.setItem('slideactive', 'false')
          }
        },
        (error) => this._util.error(error, "error")
      );
    }
  }

  signOut(): void {
    this._appInsightService.clearUserId();
    this.authService.logout();
    window.location.reload();
  }

  cpUser: User = new User();
  ResetPassword(form) {
    this.cpUser = new User();
    form.reset();
    this.pwdmodal.open();
  }
  Close() {
    this.cpUser = new User();
    this.pwdmodal.close();
  }
  isInvalidpassword: boolean = false;
  ValidateOldPassword(toSend) {
    if (toSend) {
    }
  }
  OnChange() {
    this.isInvalidpassword = false;
  }

  ChangePassword() {
    this.pwdchangeData.token = this.LoggedInUserEmail;
    if (this.pwdchangeData.token.length == 0) {
      this._util.error("Invalid Request.", "error");
      this.alertLabel = "Invalid User. Please try again";
      return;
    }
    if (
      this.pwdchangeData.newPassword == "" ||
      this.pwdchangeData.newPassword == undefined ||
      this.pwdchangeData.confirmPassword == "" ||
      this.pwdchangeData.confirmPassword == undefined
    ) {
      this.alertLabel = "Both fields are required.";
      return;
    }

    if (this.pwdchangeData.newPassword != this.pwdchangeData.confirmPassword) {
      this.alertLabel = "Confirm password is not same as password.";
      return;
    }

    var number = /([0-9])/;
    var alphabets = /([a-zA-Z])/;
    var special_characters = /([~,!,@,#,$,%,^,&,*,-,_,+,=,?,>,<])/;
    var val = this.pwdchangeData.newPassword;

    if (val.length < 8) {
      this._util.warning("Password should be atleast 8 characters.", "warning");
      return;
    }
    if (val.length < 8) {
      this._util.warning("Password should be atleast 8 characters.", "warning");
      return;
    }
    if (
      val.match(number) &&
      val.match(alphabets) &&
      val.match(special_characters)
    ) {
      var encrypted = this.authService.setEncryption(
        this.encryptionKey,
        this.pwdchangeData.newPassword
      );
      this.pwdchangeData.actualpassword = this.pwdchangeData.newPassword;
      this.pwdchangeData.newPassword = encrypted;
      this.pwdchangeData.confirmPassword = encrypted;
      this.pwdchangeData.token = this.LoggedInUserEmail;
      this.isSubmittedForPwdChange = true;
      this.authService.ResetPassword(this.pwdchangeData).subscribe(
        (data) => {
          if (data.result == "Success") {
            this.alertLabel = "";
            this._util.success(
              "Password has been changed successfully. Please login again.",
              "Success",
              "Success"
            );
            this.Close();
            this.authService.logout();
            this.router.navigate(["/auth/login"]);
          } else {
            this.pwdchangeData.newPassword = this.pwdchangeData.actualpassword;
            this.pwdchangeData.confirmPassword =
              this.pwdchangeData.actualpassword;
            this.alertLabel = data.result;
          }
          this.isSubmittedForPwdChange = false;
        },
        (error) => this._util.error(error, "error")
      );
    } else {
      this._util.warning(
        "Your password must be of minimum 8 character long and contains one upper case and one lower case alphabet with at least one number and one special character.",
        "warning"
      );
    }
  }

  open() {
    this.modal.open();
  }
  triggerChangeImage() {
    $("#file").trigger("click");
  }
  UserImageValue: any;
  UserImage: FileList;
  handleInputChange(ctrl, e) {
    var file = e.dataTransfer ? e.dataTransfer.files : e.target.files;
    if (file.length > 0) {
      var pattern = /image-*/;
      var reader = new FileReader();
      if (!file[0].type.match(pattern)) {
        this._util.error("invalid format", "Alert");
        return;
      }
      if(file[0].size/ 1024 / 1024 > 10){
        this._util.error("File size should be less than 10 MB", "Alert");
        return;
 }
      this.UserImage = file;
      let formData: FormData = new FormData();
      for (let i = 0; i < this.UserImage.length; i++) {
        formData.append("UserImage", this.UserImage[0]);
      }
      formData.append("UserID", this.UserInfo.UserId.toString());
      this.commonser.UploadUserImage(formData).subscribe((data) => {
        if (data.result == "Success") {
          this.LogInUserImageSrc = data.url;
        }
        this._util.success(
          "Profile image has been changed successfully",
          "Success",
          "Success"
        );
        setTimeout(() => {
          this.signOut()
        }, 3000);
      

      });
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsDataURL(file[0]);
    }
  }
  _handleReaderLoaded(e) {
    var reader = e.target;
    this.LogInUserImageSrc = reader.result;
  }

  CancelConfirm() {
    
    if (this.slideactive == true) {
      this.slideactive = false
    } else {
      this.slideactive = true
    }
    $("#logoutconfirm").modal("hide");
  }

  confirmToggle() {

  $('#logoutconfirm').modal({backdrop: 'static', keyboard: false}, 'show');

  }

  // logedConfirm() {
 
  //     var valueset;
  //     if (this.slideactive == true) {
  //       valueset = 1;
  //     } else {
  //       valueset = 0;
  //     }
  //     this.authService.changeeact(valueset).subscribe(
  //       (data) => {
  //         if (valueset == 0) {
  //           localStorage.setItem('slideactive', 'false')
  //         }
  //         else {
  //           localStorage.setItem('slideactive', 'true')
  //         }

  //       },
  //       (error) => this._util.error(error, "error")
  //     );
  //     this.signOut()

  // }
}
