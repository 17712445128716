import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import {FormBuilder, NgForm } from '@angular/forms';
import { NgModel } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { CountriesService } from './countries.service';
@Component({
  selector: 'Countries',
  providers: [CountriesService],
  template:
    '<select #country="ngModel" name="country" [(ngModel)]="countryID" class="form-control" (change)="onCountryChange($event.target.value)" [required]="setRequiredCountry"> <option [value]="undefined" disabled="disabled">--Select Country--</option><option *ngFor="let c of countriesList" [value]="c.CountryID">{{c.CountryName }}</option></select>',
})
export class AllCountries {
  countriesList: any;
  selectedCountryID: number;
  @Output() countryIDChange = new EventEmitter();
  @Input('form') setForm: NgForm;
  @Input() setRequiredCountry: boolean = false;
  @Input()
  get countryID() {
    return this.selectedCountryID;
  }

  set countryID(val) {
    this.selectedCountryID = val;
    this.countryIDChange.emit(this.selectedCountryID);
  }
  constructor(
    private countriesService: CountriesService,
    private _router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {}

  @ViewChild('country') ngModelCountry: NgModel;

  ngAfterViewInit() {
    this.setForm.control.addControl(
      'CountryField',
      this.ngModelCountry.control
    );
  }

  ngOnInit() {
    this.countriesService.loadCountries().subscribe((types) => {
      this.countriesList = types;
    });
  }

  onCountryChange(typeValue) {
    this.countryID = typeValue;
  }
}
