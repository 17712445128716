import {
  Component,
  EventEmitter,
  ViewChild,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { RegionService } from './region.service';
import { Region, Area, TypeGroupModel, SaveGroupModel } from './region.model';
import { TypeLookUp } from './typelookup.model';
import { RegionProperties } from './region.properties';
import { CommonService } from '../../shared/common.service';
import { GlobalVariableService } from '../../shared/globalvariable.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import {  Util } from '../../app.util';
import { SidebarService } from '../sidebar/sidebar.service';
import { BsModalComponent } from 'ng2-bs3-modal';
import { ApplicationInsightsService } from 'src/app/ApplicationInsightsService';
import { brand } from '../user/user.model';
import { message} from '../../controls/pop/index';

declare var $: any;

@Component({
  selector: 'customer-region',
  providers: [
    DatePipe,
    ApplicationInsightsService
  ],

  templateUrl: './customerregion.template.html'
})
export class CustomerRegions extends RegionProperties {
  UserId: number;
  ConfigGroup: string;
  isSaveClick: boolean;
  dropdownList: Array<brand>;
  servicetypeArray: Array<TypeGroupModel>;
  labeltypeArray: Array<TypeGroupModel>;
  selectedBrands: Array<brand>;
  selectgroupsave: SaveGroupModel;
  dropdownSettings = {};
  selectedItems = [];
  itemToDeleteData:any;

  @Input() SelectedRegion: any;
  @Input() Scope: any;
  @Input() access: any;
  @Output() EditorVisibilityChange = new EventEmitter();
  @ViewChild('CountryPopUp') modalCountry: BsModalComponent;
  @ViewChild('mydeleteModal') mydeleteModal: BsModalComponent;
  @ViewChild("pop", { static: true }) _popup: message;

  IsCountryEdit: boolean = false;
  constructor(
    private _util: Util,
    private _menu: SidebarService,
    private _router: Router,
    private $Region: RegionService,
    public commonService: CommonService,
    private _globalService: GlobalVariableService,
    private activateRoute: ActivatedRoute,
    private _datePipe: DatePipe,

    private _appInsightService: ApplicationInsightsService
  ) {
    super();
    this.moduleTitle = this._menu.menus.filter(
      f => f.routeKey === this._router.url
    )[0].title;
    var partnerinfo = _globalService.getItem('partnerinfo');
    this.UserId = partnerinfo[0].UserId;
    this.popupSelectedCountries = [];

    this.selectedBrands = [];
    this.CountryGridOptions = {
      rowData: this.countries,
      columnDefs: this.CountryColumnDefs,
      enableColResize: true,
      enableServerSideSorting: false,
      rowSelection: 'multiple',
      rowDeselection: true,
      rowModelType: 'infinite',
      pagination: true,
      paginationPageSize: 300,
      cacheOverflowSize: 2,
      maxConcurrentDatasourceRequests: 2,
      infiniteInitialRowCount: 1,
      maxBlocksInCache: 2,
      cacheBlockSize: 300,
      rowHeight: 38,
      suppressRowClickSelection: true,
      context: {
        componentParent: this
      }
    };
    this.customerGridOptions = {
      rowData: this.selectedCountries,
      columnDefs: this.customercolumnDefs,
      enableColResize: true,
      enableServerSideSorting: false,
      rowSelection: 'multiple',
      rowDeselection: true,
      rowModelType: 'infinite',
      pagination: true,
      paginationPageSize: 300,
      cacheOverflowSize: 2,
      maxConcurrentDatasourceRequests: 2,
      infiniteInitialRowCount: 1,
      maxBlocksInCache: 2,
      cacheBlockSize: 300,
      rowHeight: 38,
      context: {
        componentParent: this
      }
    };
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'PartnerID',
      textField: 'PartnerName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 2,
      allowSearchFilter: true
    };
  }

  rrgridapi = null;
  onReasonCountryMapGridReady(gridParams) {
    this.rrgridapi = gridParams.api;
    this.rrgridapi.setRowData(this.selectedCountries);
  }
  //----------------------------------------------------------------------------------------
  // this function is for firt visit of page
  //----------------------------------------------------------------------------------------
  ngOnInit() {
    
    $("#countryModal .modal-dialog").addClass("modal-xl");
    let typegroups: Array<string> = [];
    typegroups.push('UserServicetype');
    typegroups.push('SRODileveryType');
    this.commonService.getTypeLookUpsByName(typegroups).subscribe(rulegroup => {
      console.log('typegroup', rulegroup);
      this.servicetypeArray = rulegroup.filter(
        a => a.TypeGroup == 'UserServiceType',
        a => a.TypeLookUpID == 'UserServiceType'
      );
      this.labeltypeArray = rulegroup.filter(
        a => a.TypeGroup == 'SRODileveryType',
        a => a.TypeLookUpID == 'SRODileveryType'
      );
      console.log('UserServiceType', this.servicetypeArray);
      console.log('SRODileveryType', this.labeltypeArray);
    });

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'TypeCode',
      idField1: 'TypeLookUpID',
      textField: 'TypeName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 2,
      allowSearchFilter: true
    };

    this._appInsightService.logPageView(
      'Country Tab inside Region',
      this._router.url
    );

    this.LocalAccess = this.access;
    this.gridOptions.datasource = this.dataSource;
    this.FIllCountry();
    this.InitCarrierGateway();
    this.commonService.loadBrands().subscribe(
      _partners => {
        this.dropdownList = _partners;
      },
      error => (this.errorMessage = <any>error)
    );
  }
  //----------------------------------------------------------------------------------------
  // this function is for populating country in view
  //----------------------------------------------------------------------------------------
  FIllCountry() {
    this.CountryDataSource = {
      rowCount: null, 
      paginationPageSize: 300,
      paginationOverflowSize: 300,
      maxConcurrentDatasourceRequests: 2,
      maxPagesInPaginationCache: 2,
      getRows: (params: any) => {
        var sortColID = null;
        var sortDirection = null;
        if (typeof params.sortModel[0] != 'undefined') {
          sortColID = params.sortModel[0].colId;
          sortDirection = params.sortModel[0].sort;
        }
        this.$Region.getAllCountries(this.SelectedRegion.RegionID).subscribe(
          _Orders => {
            // debugger
            this.countryListPopup = _Orders;
            if (this.SelectedRegion.RegionID == 0) {
              this.selectedCountries = [];
              this.rrgridapi.setRowData(this.selectedCountries);
              this.rrgridapi.sizeColumnsToFit();
            } else if (this.SelectedRegion.RegionID != 0) {
              

              this.selectedCountries= _Orders.recordsets[0];
              this.rrgridapi.setRowData(this.selectedCountries);
              this.rrgridapi.sizeColumnsToFit();
            }

            
            this.countries = _Orders.recordsets[1];
            if (!this.CountryGridOptions.columnApi.getAllColumns())
              this.CountryGridOptions.api.setColumnDefs(this.CountryColumnDefs);
            var lastRow = this.countries.length;
            params.successCallback(this.countries, lastRow);
          },
          error => (this.errorMessage = <any>error)
        );
      }
    };
    this.CountryGridOptions.datasource = this.CountryDataSource;
  }

  InitComponent() {
    console.log("InitComponent");
  }

  InitCarrierGateway() {
    this.$Region.getAllCarrierGateway(this.SelectedRegion.RegionID).subscribe(
      _AllCarrierGatewayCollection => {
        this.AllCarrierGateway = JSON.parse(
          JSON.stringify(_AllCarrierGatewayCollection)
        );
        this.AllCarrierGatewayCollection = _AllCarrierGatewayCollection.map(
          function(e) {
            return new TypeLookUp(e);
          }
        );
        this.selectedCarrierGateway = this.AllCarrierGateway[0].TypeCode;
        this.onSelectedCarrierGatewayChanged();
      },
      error => (this.errorMessage = <any>error)
    );
  }
  //----------------------------------------------------------------------------------------
  // this function is for region assign from service api 
  //----------------------------------------------------------------------------------------
  onSelectionChanged() {
    this.CurrentRegion = this.gridOptions.api.getSelectedRows()[0];
    if (!this.CurrentRegion) {
      this.CurrentRegion = new Region();
    }
  }
  //----------------------------------------------------------------------------------------
  // this function is for gateway selected 
  //----------------------------------------------------------------------------------------
  onSelectedCarrierGatewayChanged() {
    this.CarrierGatewayAttribute = this.AllCarrierGatewayCollection.filter(
      d => d.TypeCode === this.selectedCarrierGateway
    ).map(function(e) {
      e.Attributes = $.grep(e.Attributes, function(f, i) {
        if (f.AttributeValue && f.AttributeValue == 'false') {
          f.AttributeValue = false;
        } else if (f.AttributeValue && f.AttributeValue == 'true') {
          f.AttributeValue = true;
        } else if (f.TypeName == 'Brands') {
          if (f.AttributeValue) {
            if (f.AttributeValue[0]) {
              if (!f.AttributeValue[0].PartnerID) {
                f.AttributeValue = JSON.parse(f.AttributeValue);
              }
            }
          } else f.AttributeValue = [];
        }
        return f;
      });
      return e.Attributes;
    })[0];
  }

  Save(regionId: number): void {
    console.log(regionId)
  }
  //----------------------------------------------------------------------------------------
  // this function is for close form
  //----------------------------------------------------------------------------------------
  CancelForm() {
    this.EditorVisibilityChange.emit(false);
  }
  //----------------------------------------------------------------------------------------
  // this function is for sform submission 
  //----------------------------------------------------------------------------------------
  onSubmit(form: any) {
    if (!form.valid) {
      for (var i in form.controls) {
        form.controls[i].markAsTouched();
      }
      form.valueChanges.subscribe(data => {
        this.isSaveClick = !form.valid;
      });
      this.isSaveClick = true;
      return;
    }

    var countryIds = '';
    if (this.selectedCountries.length > 0) {
      for (var countryDtl of this.selectedCountries) {
        countryIds += countryDtl.CountryID + ',';
      }
    }

    this.SelectedRegion.countryIds = countryIds.substr(
      0,
      countryIds.length - 1
    );
    if( this.selectedCountries.length==0)
    {
      this._util.error('Please add minimum 1 country ', 'Error');
      return process.exit(0);
    }
    this.selectedCountries.forEach(element => {
            
            
      if(element.ISConfigured === false)
      {
        //console.log("found")
        this._util.error('Please configure country '+ element.CountryName, 'Error');
        return process.exit(0);
      }
    });
    
    this.SelectedRegion.Gateways = this.CarrierGatewayAttribute;
    
    
    console.log('test',JSON.stringify (this.SelectedRegion));
    this.$Region.Save(this.SelectedRegion).subscribe(
      _result => {
        if (_result.ReturnRegionID == 1) {
          this._util.success('Region Saved successfully', 'Success');
          this.EditorVisibilityChange.emit(true);
        }
       else if (_result.ReturnRegionID == 2) {
          this._util.success('Region/Country saved successfully', 'Success');
          this.EditorVisibilityChange.emit(true);
        } else {
          this._util.error('Region Name already exist', 'Error');
        }
      },
      error => {
        this._util.error('Region Name already exist', 'Error');
      }
    );
  }

  //----------------------------------------------------------------------------------------
  // this function is for opening a popup
  //----------------------------------------------------------------------------------------
  AddCountry() {
    
    this.CountryGridOptions.api.deselectAll();
    this.modalCountry.open();
  }
  //----------------------------------------------------------------------------------------
  // this function is for selecting country 
  //----------------------------------------------------------------------------------------
  SelectedCountry() {
    this.popupSelectedCountries = this.CountryGridOptions.api.getSelectedRows();
    if (this.popupSelectedCountries.length <= 0) {
      this._util.error('Atleast one country is required.', 'Error');
      return false;
    }
    if (this.SelectedRegion.RegionID == 0) {
      if (this.popupSelectedCountries.length > 0) {
        this.rrgridapi.setRowData(this.selectedCountries);
        let dd = this.countries.filter(item =>
          this.popupSelectedCountries.some(f => f.CountryID == item.CountryID)
        );
        for (var countryDtl of dd) {
          countryDtl.IsSelected = true;
          var aa = {
            CountryID: countryDtl.CountryID,
            CountryName: countryDtl.CountryName,
            CountryCode: countryDtl.CountryCode,
            CurrencyCode: countryDtl.CurrencyCode,
            CurrencySymbol: countryDtl.CurrencySymbol,
            DollarExchangeRate: countryDtl.DollarExchangeRate,
            RegionID: countryDtl.RegionID,
            RegionName: countryDtl.RegionName,
            TeleCode: countryDtl.TeleCode,
            ISConfigured:false
          };
          this.selectedCountries.push(aa);
        }
        this.rrgridapi.setRowData(this.selectedCountries);
        this.rrgridapi.sizeColumnsToFit();
      }
    } else {
      if (this.popupSelectedCountries.length > 0) {
        
        let dd = this.countries.filter(item =>
          this.popupSelectedCountries.some(f => f.CountryID == item.CountryID)
        );
        for (var countryDtl of dd) {
          countryDtl.IsSelected = true;
          var ab = {
            CountryID: countryDtl.CountryID,
            CountryName: countryDtl.CountryName,
            CountryCode: countryDtl.CountryCode,
            CurrencyCode: countryDtl.CurrencyCode,
            CurrencySymbol: countryDtl.CurrencySymbol,
            DollarExchangeRate: countryDtl.DollarExchangeRate,
            RegionID: countryDtl.RegionID,
            RegionName: countryDtl.RegionName,
            TeleCode: countryDtl.TeleCode,
            IsSelected:false,
            ISConfigured:false
          };

        
          this.selectedCountries.forEach(element => {
            
            
            if(element.CountryID === ab.CountryID)
            {
              this._util.error('Country already added', 'Error');
              return process.exit(0);
            }
          });
         
          this.selectedCountries.push(ab);


        }
       
        this.rrgridapi.setRowData(this.selectedCountries);
        this.rrgridapi.sizeColumnsToFit();
      }
    }

    this.modalCountry.close();
  }

  countryidget;
  //----------------------------------------------------------------------------------------
  // this function is for listing of country
  //----------------------------------------------------------------------------------------
  getcountry(e) {
    
    this.countryidget = e.data.CountryID;
    // debugger;
    this.selectgroupsaves=''
    this.selectgroupsave1=''
    this.enduserservice=''
    this.enduserlabel=''
    this.commonService.loadRegionConfig( this.countryidget).subscribe(
      _partners => {
        // debugger;
        this.selectgroupsaves= JSON.parse(_partners[0].partner_serviceType)
        this.selectgroupsave1= JSON.parse(_partners[0].partner_labelType)
        this.enduserservice=JSON.parse(_partners[0].enduser_serviceType)
        this.enduserlabel=JSON.parse(_partners[0].enduser_labelType)
      },
      error => (this.errorMessage = <any>error)
    );
  }
  //----------------------------------------------------------------------------------------
  // this function is for delting particular country
  //----------------------------------------------------------------------------------------
  

  deleteCountry(e) {
    var me:any=this;
   
    if (e.event.target != undefined) {
      let actionType = e.event.target.getAttribute('Id');
      if (actionType == 'deleteCountry') {
        this.mydeleteModal.open();
        me.itemToDeleteData = e.data;
       
      
      }
    }
  }

  DeleteCountry()
  {
    
    var me:any=this
    var idx = this.selectedCountries.indexOf(me.itemToDeleteData);
    this.selectedCountries.splice(idx, 1);
    this.rrgridapi.setRowData(this.selectedCountries);
// debugger;
    console.log("me.itemToDeleteData.CountryID",me.itemToDeleteData.CountryID);

    this.commonService.deleteCountryConfig( me.itemToDeleteData.CountryID).subscribe(
      _partners => {
        // debugger;
        this.mydeleteModal.close();
        this._util.success('Country Deleted successfully', 'Success');
      },
      error => (this.errorMessage = <any>error)
    );

  
    
  }
  onItemSelect(item) {
    console.log(item)
  }
  onItemSelect1(item) {
    console.log(item)
  }
  getervicetype: any = [];
  getervicetype1: any = [];

  onSelectAll(items: any) {
    console.log(items);
  }
  selectgroupsave1;
  checkboxespartner;
  selectgroupsaves;
  data: any = [];
  //----------------------------------------------------------------------------------------
  // this function is for submitting form
  //----------------------------------------------------------------------------------------
  
  onSubmit1(e) {
    var me:any=this
    this.data = [];
    if (this.checkboxespartner == undefined) {
      this.checkboxespartner = 0;
    }
    if (this.endusercheckbox == undefined) {
      this.endusercheckbox = 0;
    }
    if (this.checkboxespartner == true) {
      this.checkboxespartner = 1;
    }
    if (this.endusercheckbox == true) {
      this.endusercheckbox = 1;
    }
    if (
      this.selectgroupsaves == undefined ||
      this.selectgroupsaves.length == 0
    ) {
      this._util.error('Please Select Service type in partners .', 'Error');
    } else if (
      this.selectgroupsave1 == undefined ||
      this.selectgroupsave1.length == 0
    ) {
      this._util.error('Please Select Label type  in partners .', 'Error');
    } else if (
      this.enduserservice == undefined ||
      this.enduserservice.length == 0
    ) {
      this._util.error('Please Select Service type in End user .', 'Error');
    } else if (
      this.enduserlabel == undefined ||
      this.enduserlabel.length == 0
    ) {
      this._util.error('Please Select Label type in end user.', 'Error');
    } else {
      this.data.push({
        region_id: this.SelectedRegion.RegionID,
        country_id: this.countryidget,
        partner_serviceType_Id: this.selectgroupsaves,
        partner_labelType_Id: this.selectgroupsave1,
        partner_Cycleon: this.checkboxespartner,
        enduser_serviceType_Id: this.enduserservice,
        enduser_labelType_Id: this.enduserlabel,
        enderuser_Cycleon: this.endusercheckbox
      });
      // console.log("data",JSON.stringify(me.data));
      var ctrID=me.data[0].country_id;
      
      // debugger;
      // console.log("me.data",me.data);
      this.$Region.SaveContryConfiguration(me.data).subscribe(
        result => {

          this.selectedCountries.forEach(element => {
            console.log("me.data.country_id",me.data[0].country_id);
            if(element.CountryID ==ctrID)
            {
              element.ISConfigured =true
              
            }
          });

          setTimeout(() => {
            $('#myModal').modal('toggle');
            return false;
          }, 100);
        },
        error => {
          this._util.error(error, 'Error');
        }
      );
    }
  }
  
  endusercheckbox;
  enduserlabel;
  enduserservice;
}
