import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs-compat/Observable';
import { ConfigurationConstants } from '../constants';

@Injectable()
export class DownloadService {
  private baseUrl: string;
  constructor(private http: HttpClient) {
    this.baseUrl = ConfigurationConstants.BASEURL;
  }
  downloadExcel(moduleName: string): Observable<any> {
    console.log(moduleName);
    return this.http.get(`${this.baseUrl}reports/download/${moduleName}`);
  }
  // downloadAccountExcel(moduleName: string): Observable<any> {
  //   console.log(moduleName);
  //   return this.http.get(`${this.baseUrl}reports/downloadAccount/${moduleName}`);
  // }
}