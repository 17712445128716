import { Component, Inject, OnInit } from "@angular/core";
import {
  Router,
} from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { WINDOW } from "../app.window";
import { environment } from "src/environments/environment";
@Component({
  selector: "app-authentication",
  templateUrl: "./authentication.component.html",
  styleUrls: ["./authentication.component.css"],
})
export class AuthenticationComponent implements OnInit {
  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(DOCUMENT) private _document: HTMLDocument,
    private router: Router
  ) {}
   scope: string=''
  ngOnInit() {
    var host = this.window.location.hostname;
    this.scope = host.split(".")[0];
    this._document.title = "Jabra";
    if (environment.envsource == "prod") {
      if (location.protocol === "http:") {
        window.location.href = location.href.replace("http", "https");
      }
    }
    this._document
      .getElementById("appFavicon")
      .setAttribute("href", "assets/img/jabra-favicon.ico");
  }
}
