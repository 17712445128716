import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd, NavigationStart } from "@angular/router";
import { SidebarService } from "../sidebar/sidebar.service";
import { Util } from "src/app/app.util";
import { AuthService } from "src/app/authentication/auth.service";
declare var $: any;
@Component({
  selector: "app-command",
  templateUrl: "./command.component.html",
  styleUrls: ["./command.component.css"]
})
export class CommandComponent implements OnInit {
  home_route: string = "";
  _route: string = "";

  constructor(
    private router: Router,
    private _menu: SidebarService,
    private _util: Util,
    private _auth: AuthService
  ) {
    router.events.forEach(event => {
      if (event instanceof NavigationStart) {
        $(".rl-scanner").addClass("rl-scanner-inactive");
        $("body").removeClass("no-scroll");
      } else if (event instanceof NavigationEnd) {
        this.home_route =
          (router.url.split("/")[1] == "back-office"
            ? "/back-office"
            : "/manage") + "/home";

        if(this.router.url.includes('globalqueueold'))
        {
          var _thisRoute =
          (this._menu.menus &&
            this._menu.menus.filter(
              f => this.router.url.includes(f.routeKey)
            )[1]) ||
          null;
        this._route = _thisRoute ? _thisRoute.description : "";
        }
        else{
          var _thisRoute =
          (this._menu.menus &&
            this._menu.menus.filter(
              f => this.router.url.includes(f.routeKey)
            )[0]) ||
          null;
        this._route = _thisRoute ? _thisRoute.description : "";
        }
         
      }
    });
  }

  ngOnInit() {

  }

}
