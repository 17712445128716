import { Component, Inject, OnInit } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { WINDOW } from "../app.window";
import { environment } from "src/environments/environment";

declare var $: any;
@Component({
  selector: "app-back-office",
  templateUrl: "./back-office.component.html",
  styleUrls: ["./back-office.component.css"],
})
export class BackOfficeComponent implements OnInit {
  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(DOCUMENT) private _document: HTMLDocument
  ) {}

  ngOnInit() {
    $(".loading-container").addClass("loading-inactive");
    var host = this.window.location.hostname;
    let scope: string = host.split(".")[0];
    if (environment.envsource == "prod") {
      if (location.protocol === "http:") {
        window.location.href = location.href.replace("http", "https");
      }
    }
    this._document.title = "Jabra";
    this._document
      .getElementById("appFavicon")
      .setAttribute("href", "assets/img/jabra-favicon.ico");
  }
}
