import {  Component} from '@angular/core'
import { GridOptions } from 'ag-grid-community';
import { Router } from '@angular/router';
import { GlobalVariableService } from '../../../shared/globalvariable.service';
import { OrderTabSource } from '../../OrderTabManager/OrderTabSource.Model';
@Component({
    selector: 'OrderLog',
    templateUrl: './OrderLog.html'
})
export class OrderLog {
    Source: any;
    selectedId: number;
    type: string;
    IsEditorVisible: boolean = false;
    ModuleName: any;
    orderTabSource: OrderTabSource = new OrderTabSource();
    columnDefs = [
        { headerName: 'Item #', field: "ItemNumber", cellRenderer: 'group' },
        { headerName: "MRNHeaderID", field: "MRNHeaderID", hide: true },
        { headerName: "DispatchHeaderID", field: "DispatchHeaderID", hide: true },
        { headerName: 'Action Type', field: "ActionType", width: 150 },
        { headerName: 'ActionTypeID', field: "ActionTypeID", hide: true },
        { headerName: 'Receiving Number', field: "MRNNumber", width: 200 },
        { headerName: 'Dispatch Number', field: "DispatchNumber", width: 200 },
        { headerName: 'Action Type Number', field: "ActionTypeNumber", width: 150 },
        { headerName: 'Start Date', field: "StartDate", width: 150 },
        { headerName: 'Task', field: "Task", width: 100 },
        { headerName: 'Item Name', field: "ItemName", },

        { headerName: 'Serial #', field: "SerialNumber" },
        { headerName: 'Quantity', field: "Quantity", width: 80 },
        { headerName: 'Created By', field: "CreatedBy", width: 100 },
        { headerName: 'Aging Days', field: "AgingDays", width: 100 }
    ]

    constructor(private _router: Router, private _global: GlobalVariableService) { }

    cellEditRender(val) {
        if (!val) {
            return '<a style="cursor:pointer;" data-action-type="View">View</a>';
        }
        return val.value;
    }

    

    gridOptionsOrderLog: GridOptions;
    ngOnInit() {
        if (this.Source.length > 0) {
            if (this.Source[0].recordset) {
                this.Source = JSON.parse(this.Source[0].recordset);
            } else {
                this.Source = [];
            }
        }
        this.gridOptionsOrderLog = {
            rowData: this.Source,
            columnDefs: this.colDefs(),
            enableColResize: true,
            enableSorting: true,
            getNodeChildDetails: this.getNodeChildDetails,
            onGridReady: function (params) {
                params.api.sizeColumnsToFit();
            },
            rowSelection: 'single'
        }
    }

    onRowClicked(e) {
        if (e.event.target !== undefined) {
            let data = e.data;
            let actionType = e.event.target.getAttribute("data-action-type");
            if (actionType == "View") {
                this._global.TaskQue = e.data;
                if (this.ModuleName == 'sto') {
                    this._router.navigateByUrl('/dummy', { skipLocationChange: true })
                    setTimeout(() => this._router.navigate(['/MRNIndex'], { queryParams: { ID: 0, RefID: e.data.MRNHeaderID, Type: "view" } }));
                } if (this.ModuleName == 'disposal') {
                    this._router.navigateByUrl('/dummy', { skipLocationChange: true })
                    setTimeout(() => this._router.navigate(['/dispatchorder'], { queryParams: { ID: 0, RefID: e.data.DispatchHeaderID, Type: "view" } }));
                } else {
                    if (data.ActionType == "Dispatch") {
                        this._router.navigateByUrl('/dummy', { skipLocationChange: true })
                        setTimeout(() => this._router.navigate(['/dispatchorder'], { queryParams: { ID: 0, RefID: e.data.ActionTypeID, Type: "view" } }));
                    } else {
                        this._router.navigateByUrl('/dummy', { skipLocationChange: true })
                        setTimeout(() => this._router.navigate(['/MRNIndex'], { queryParams: { ID: 0, RefID: e.data.ActionTypeID, Type: "view" } }));
                    }
                }
            } else {
                console.log("e");
            }
        }
    }


   

    getNodeChildDetails(rowItem) {
        if (rowItem.group) {
            return {
                group: true,
                expanded: rowItem.group === 'Group C',
                children: rowItem.participants,
                field: 'group',
                key: rowItem.group,
            };
        } else {
            return null;
        }
    }

    colDefs() {
        var defs;
        if (this.ModuleName == 'sto') {
            defs = this.columnDefs.filter(function (item) { return item.field != 'MRNNumber' && item.field != 'DispatchNumber' });
        } else if (this.ModuleName == 'disposal') {
            defs = this.columnDefs.filter(function (item) { return item.field != 'MRNNumber' && item.field != 'ActionType' && item.field != 'ActionTypeNumber' });
        } else {
            defs = this.columnDefs.filter(function (item) { return item.field != 'DispatchNumber' && item.field != 'ActionType' && item.field != 'ActionTypeNumber' });
        }
        return defs;
    }
   

}