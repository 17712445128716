export class User {
  UserID: number;
  TenantID: number;
  Initials: string;
  UserImage: string;
  FirstName: string;
  MiddleName: string;
  LastName: string;
  Email: string;
  ImageURL: string;
  IsOnline: boolean;
  DisplayDate: string;
  CellNumber: string;
  UserName: string;
  Password: string;
  PasswordConfirmation: string;
  UserType: string;
  Address1: string;
  Address2: string;
  City: string;
  StateID: string;
  ZipCode: string;
  FixedLineNumber: string;
  UserTheme: string;
  CountryID: string;
  RoleID: number;
  PartnerID: number;
  LanguageID: number;
  LanguageName: string;
  brands: any;
  UserRoles: any;
  partners: any;
  TeleCode: string;
  Scope: string;
  Action: string;
  FailedLoginAttempt: number;
  IsActive: Boolean;
  IsFailedLoginLock: Boolean;
  ActualPassword: string;
  axCustomerID: string;
  ServiceID: string;
  IsApproved: Boolean;
  Approval_RejectionDate: string;
  Approval_RejectionRemark: string;
  Approvalname: string;
  IsAuthorized: Boolean;
  user_company: string;
  user_company2: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  IsAddressAccessible: Boolean;
  constructor();
  constructor(User: User);
  constructor(User?: any) {
    this.UserID = (User && User.UserID) || 0;
    this.TenantID = (User && User.TenantID) || 0;
    this.Initials = (User && User.Initials) || undefined;
    this.UserImage = (User && User.UserImage) || "/Assets/img/default.png";
    this.FirstName = (User && User.FirstName) || "";
    this.MiddleName = (User && User.MiddleName) || "";
    this.LastName = (User && User.LastName) || "";
    this.Email = (User && User.Email) || "";
    this.CellNumber = (User && User.CellNumber) || "";
    this.UserName = (User && User.UserName) || "";
    this.Password = (User && User.Password) || "";
    this.PasswordConfirmation = (User && User.PasswordConfirmation) || "";
    this.UserType = (User && User.UserType) || "";
    this.Address1 = (User && User.Address1) || "";
    this.Address2 = (User && User.Address2) || "";
    this.City = (User && User.City) || "";
    this.StateID = (User && User.StateID) || undefined;
    this.ZipCode = (User && User.ZipCode) || "";
    this.FixedLineNumber = (User && User.FixedLineNumber) || "";
    this.UserTheme = (User && User.UserTheme) || "";
    this.CountryID = (User && User.CountryID) || undefined;
    this.FixedLineNumber = (User && User.FixedLineNumber) || "";
    this.FixedLineNumber = (User && User.FixedLineNumber) || "";
    this.RoleID = (User && User.RoleID) || 0;
    this.PartnerID = (User && User.PartnerID) || 0;
    this.LanguageID = (User && User.LanguageID) || undefined;
    this.brands = (User && User.brands) || [];
    this.UserRoles = (User && User.UserRoles) || [];
    this.partners = (User && User.partners) || [];
    this.TeleCode = (User && User.TeleCode) || "";
    this.Scope = (User && User.Scope) || "PTR001";
    this.Action = (User && User.Action) || "";
    this.FailedLoginAttempt = (User && User.FailedLoginAttempt) || 0;
    this.IsActive = (User && User.IsActive) || true;
    this.IsFailedLoginLock = (User && User.IsFailedLoginLock) || false;
    this.ActualPassword = (User && User.ActualPassword) || "";
    this.axCustomerID = (User && User.axCustomerID) || "";
    this.ServiceID = (User && User.ServiceID) || "";
    this.IsApproved = (User && User.IsApproved) || false;
    this.IsAuthorized = (User && User.IsAuthorized) || false;
    this.Approval_RejectionDate = (User && User.Approval_RejectionDate) || "";
    this.Approval_RejectionRemark =
      (User && User.Approval_RejectionRemark) || "";
    this.Approvalname = (User && User.Approvalname) || "";
    this.user_company = (User && User.user_company) || "";
    this.user_company2 = (User && User.user_company2) || "";
    this.addressLine1 = (User && User.addressLine1) || "";
    this.addressLine2 = (User && User.addressLine2) || "";
    this.city = (User && User.city) || "";
    this.state = (User && User.state) || "";
    this.zipCode = (User && User.zipCode) || "";
    this.country = (User && User.country) || "";
    this.IsAddressAccessible = (User && User.IsAddressAccessible) || true;
  }
}

export class Address {
  InternalCode: string;
  Address: string;
  City: string;
  Country: string;
  PhoneNumber: string;
  IsActive: boolean;
  constructor();
  constructor(address: Address);
  constructor(address?: any) {
    this.InternalCode = (address && address.InternalCode) || "";
    this.Address = (address && address.Address) || "";
    this.City = (address && address.City) || "";
    this.Country = (address && address.Country) || "";
    this.PhoneNumber = (address && address.PhoneNumber) || "";
    this.IsActive = (address && address.IsActive) || 1;
  }
}

export class UserAddress {
  ID: number;
  UserId: number;

  Address1: string;
  Address2: string;
  City: string;
  State: string;
  PostalCode: string;
  Country: string;
  PhoneNumber: string;
  CountryID: number;
  Building: string;
  Company: string;
  IsActive: boolean;
  Company2: string;
  ContactName: string;
  ContactEmail: string;
  constructor();
  constructor(userAddress: UserAddress);
  constructor(UserAddress?: any) {
    this.ID = (UserAddress && UserAddress.ID) || 0;
    this.UserId = (UserAddress && UserAddress.UserId) || 0;

    this.Address1 = (UserAddress && UserAddress.Address1) || "";
    this.Address2 = (UserAddress && UserAddress.Address2) || "";
    this.City = (UserAddress && UserAddress.City) || "";
    this.State = (UserAddress && UserAddress.State) || " ";
    this.PostalCode = (UserAddress && UserAddress.PostalCode) || "";
    this.Country = (UserAddress && UserAddress.Country) || "";
    this.PhoneNumber = UserAddress && UserAddress.PhoneNumber;
    this.CountryID = (UserAddress && UserAddress.CountryID) || 0;
    this.IsActive = (UserAddress && UserAddress.IsActive) || true;
    this.Building = (UserAddress && UserAddress.Building) || "";
    this.Company = (UserAddress && UserAddress.Company) || "";
    this.Company2 = (UserAddress && UserAddress.Company2) || "";
    this.ContactName = (UserAddress && UserAddress.ContactName) || "";
    this.ContactEmail = (UserAddress && UserAddress.ContactEmail) || "";
  }
}
export class brand {
  PartnerID: number;
  PartnerName: string;
  constructor();
  constructor(brand: brand);
  constructor(brand?: any) {
    this.PartnerID = (brand && brand.PartnerID) || 0;
    this.PartnerName = (brand && brand.PartnerName) || "";
  }
}

export class Searchfilter {
  SearchType: number;
  NormalSearch:string
  user_country: string;
  FullName: string;
  Email: string;
  user_company: string;
  source: string;
  RoleName: string;
  constructor();
  constructor(_Searchfilter?: any) {
    this.SearchType = (_Searchfilter && _Searchfilter.SearchType) || "Normal";
    this.NormalSearch = (_Searchfilter && _Searchfilter.NormalSearch) || '';
    this.user_country = (_Searchfilter && _Searchfilter.user_country) || '';
    this.FullName = (_Searchfilter && _Searchfilter.FullName) || '';
    this.Email = (_Searchfilter && _Searchfilter.Email) || '';
    this.user_company = (_Searchfilter && _Searchfilter.user_company) || '';
    this.source = (_Searchfilter && _Searchfilter.source) || 'BO';
    this.RoleName = (_Searchfilter && _Searchfilter.RoleName) || '';
  }
}
