import { Component } from '@angular/core';

// both this and the parent component could be folded into one component as they're both simple, but it illustrates how
// a fuller example could work
@Component({
  selector: 'editimage-link',
  template: `<i
      class='fa fa-check-circle fa-2x green'
      *ngIf='canActive && this.isActive'
    ></i>
    <i
      class='fa fa-times-circle fa-2x red'
      *ngIf='canActive && !this.isActive'
    ></i>`,
})
export class ImageColumnComponent {
  params: any;
  cell: any;
  isActive: boolean;
  canActive: boolean;
  agInit(params: any): void {
    this.params = params;
    if (!params.data) {
      this.canActive = false;
      return;
    } else {
      this.canActive = true;
    }
    if (params.colDef.field === 'IsActive') {
      this.isActive = params.data.IsActive;
    } else if (params.colDef.field === 'requiredonCustomerPortal') {
      this.isActive = params.data.requiredonCustomerPortal;
    } else if (params.colDef.field === 'RequiredonBackOffice') {
      this.isActive = params.data.RequiredonBackOffice;
    } else {
      this.isActive = params.data.IsActive;
    }
  }
}
