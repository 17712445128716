import { Component, Input, OnInit } from "@angular/core";
import { ExportToCsv } from "export-to-csv";
import { Util } from "src/app/app.util";
import { DownloadService } from "./DownloadService";
import { ExcelService } from "./excel.service";

@Component({
  selector: "app-download",
  templateUrl: "./download.component.html",
  styleUrls: ["./download.component.css"],
  providers: [DownloadService],
})
export class DownloadComponent implements OnInit {
  @Input() moduleName: string;
  @Input() permission: boolean;
  rowsdatavalueexcel: any = [];
  csvtitleName: string = '';
  @Input() isFlag: boolean;
  constructor(
    private _DownloadService: DownloadService,
    private _util: Util,
    private excelService: ExcelService
  ) { }

  ngOnInit(): void {
    console.log(this.moduleName);
    // CSV file title name calculated
    for (let i = 0; i <= this.moduleName.length - 1; i++) {
      if (i == 0) {
        this.csvtitleName = this.csvtitleName + this.moduleName.charAt(i).toUpperCase();
      }
      else {
        this.csvtitleName = this.csvtitleName + this.moduleName.charAt(i);
      }
    }
  }
  // download method
  download(type) {
    this.rowsdatavalueexcel = [];
    this._DownloadService.downloadExcel(this.moduleName).subscribe(
      (result) => {
        this.rowsdatavalueexcel = result[0];
        console.log(this.rowsdatavalueexcel);
        if (type == "e") {
          this.excelService.exportAsExcelFile(
            this.rowsdatavalueexcel,
            this.moduleName
            // this.csvtitleName
          );
        } else if (type == "c") {
          this.onExportToCSV();
        }
      },
      (error) => {
        console.log(error)
      }
    );
  }

  //----------------------------------------------------------------------------------------
  // this function is for downloading xls file
  //----------------------------------------------------------------------------------------
  onExportToExcel(): void {
    this.excelService.exportAsExcelFile(this.rowsdatavalueexcel, "sample");
  }

  options: any = [];
  data: any = [];
  //----------------------------------------------------------------------------------------
  // this function is for downloading csv file
  //----------------------------------------------------------------------------------------
  onExportToCSV(): void {
    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      filename: this.moduleName + '_export_' + new Date().getTime(),
      //filename:this.csvtitleName+ '_export_' + new Date().getTime(),
      decimalSeparator: ".",
      showLabels: true,
      showTitle: true,
      //title:this.csvtitleName, 
      title: "",
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(this.rowsdatavalueexcel);
  }
  // onDownloadAccount() {
  //   this._DownloadService
  //     .downloadExcel(this.moduleName).subscribe(
  //       (result) => {
  //         this.rowsdatavalueexcel = result[0];
  //         console.log(this.rowsdatavalueexcel);
  //         // if (type == "e") {
  //         this.excelService.exportAsExcelFile(
  //           this.rowsdatavalueexcel,
  //           this.moduleName
  //           // this.csvtitleName
  //         );
  //         // } else if (type == "c") {
  //         //   this.onExportToCSV();
  //         // }
  //       },
  //       (error) => {
  //       }
  //     );

  // }
}
